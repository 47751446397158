import { isValidTokenId } from '../helpers/validators'
import { isDgNativeTokenAddress } from '../helpers/wrappedTokenHelpers'
import { Address, TokenId, TokenNetwork, TokenV3 } from '../model'
import { getNetworkConfig } from './utils'

export const getTokenAddress = (token: TokenV3): string => {
  let address = token.address ? token.address : getTokenAddressFromId(token.id)
  if (isDgNativeTokenAddress(token.address)) {
    const networkConfig = getNetworkConfig(token.network)
    const wrappedNativeTokenAddress = networkConfig.native_token.address
    if (!wrappedNativeTokenAddress) {
      throw new Error(`native_token isn't defined in config for chain ${networkConfig.id}`)
    }
    address = wrappedNativeTokenAddress
  }
  return address
}

export const getTokenAddressFromId = (id: TokenId): Address => {
  return id ? id.split('-')[0] : id
}
export const getTokenNetworkFromId = (id: TokenId): TokenNetwork => {
  if (!isValidTokenId(id)) {
    throw new Error('Invalid token id')
  }
  return id.split('-')[1]
}

export const getIdFromTokenAddress = (address: Address, networkConfig: TokenNetwork): TokenId =>
  `${address}-${networkConfig.toLowerCase()}`

export const addressMatch = (
  value1: { address?: string },
  value2: { address?: string }
): boolean => {
  return value1.address === value2.address
}

export const idMatchesAddress = (
  value1: { address?: string },
  value2: { id?: string }
): boolean => {
  return value1.address === value2.id?.split('-')[0]
}
